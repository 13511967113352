@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

body{
  background-color: #F8F9FD;
  font-family: 'Quicksand';
  margin: 0;
}

/* TOP NAVIGATION */
.topnav-outside{
  padding: 20px 80px 0px 80px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.topnav{
  background-color: #D8E2FE;
  width: auto;
  height: auto;
  border: 1px solid #A5C1FF;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topnav a{
  color: #A5C1FF;
  float: right;
  padding: 20px 40px 20px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
}

.topnav a:hover {
  color: #2563EB;
  text-decoration: underline;
}

label.checkbox-label{
  margin-right: 20px;
  margin-left: 20px;
  background-color: #2563EB;
}

/* HOME */
.home{
  background-color: #F8F9FD;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 200px 80px 200px 80px;
}

.container-home{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-home{
  flex-basis: 70%;
}

.home h2{
  background: linear-gradient(to right, #2563EB, #7ABCF9 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 700;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

.home h1{
  background: linear-gradient(to right, #2563EB, #7ABCF9 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 56px;
  font-weight: 700;
  margin-block-start: 0em;
  margin-block-end: 0.1em;
}

.home p{
  text-align: justify;
  text-justify: inter-word;
  font-size: 24px;
  font-weight: 500;
  margin-block-start: 0em;
  margin-block-end: 0.1em;
  /* responsive text */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.profile img{
  width: 450px;
  border-radius: 100%;
}

/* EXPERIENCES */
.title {
  font-family: 'Quicksand';
  font-size: 24px;
  font-weight: 600;
}

.subtitle {
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 500;
}

.date {
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 400;
}

.accordion-page {
  background-color: #F1F3FE;
  padding-top: 120px;
  padding-bottom: 80px;
  height: auto;
}

.accordion-page h2{
  background: linear-gradient(to right, #2563EB, #7ABCF9 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0px 80px 20px 80px;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}

/* ACCORDION */
.main-accordion {
  margin-left: 80px;
  margin-right: 80px;
  width: auto;
  height: 100%;
  border: none;
  transition: all 0.4s;
  cursor: pointer;
}

.accordion{
  background-color: #F8F9FD;
  width: 100%;
  height: 100%;
  text-align: left;
  border: 1px solid #2563EB;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
}

.accordion img {
  margin: 8px;
  width: 80px;  
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion span {
  margin-left: 10px;
}

.active, .accordion:hover {
  background-color: #C6D8FF;
}

.item {
  margin-bottom: 16px;
}

.panel {
  background-color: #A5C1FF;
  margin: 8px 8px 0px 8px;
  height: auto;
  width: auto;
  padding: 20px;
  font-size: 18px;
  overflow: hidden;
  border: 1px solid #2563EB;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panel.not-ready{
  display: none;
}

.panel p {
  font-size: 18px;
  font-weight: 500;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.panel li {
  font-size: 18px;
  font-weight: 400;
}

.button {
  background-color: #2563EB;
  color: #FFFFFF;
  text-decoration: none;
  margin: 8px;
  padding: 10px 20px;
  border-radius: 100px;
  align-self: flex-end;
}

.button-group {
  display: flex;
  gap: 10px;
  align-self: flex-end;
}

/* GALLERY */
.gallery {
  background-color: #F8F9FD;
  height: auto;
  padding-top: 120px;
  padding-bottom: 80px;
}

.gallery img{
  max-width: 100%;
  height: auto;
}

.container-gallery {
  padding: 0 80px;
}

.gallery h2{
  background: linear-gradient(to right, #2563EB, #7ABCF9 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0px 0px 20px 0px;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}

.gallery p{
  padding: 0px 0px 20px 0px;
  font-size: 24px;
  font-weight: 500;
}

/* SKILLS */
.skills {
  background-color: #F1F3FE;
  height: auto;
  padding-top: 120px;
  padding-bottom: 120px;
}

.container-skills {
  padding: 0 80px;
}

.skills h2 {
  background: linear-gradient(to right, #2563EB, #7ABCF9 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
  padding: 0px 0px 20px 0px;
  text-align: center;
}

.three-container {
  display: flex;
  justify-content: space-between;
}

.item-container {
  background-color: #F8F9FD;
  margin: 20px;
  text-align: center;
  border: 1px solid #2563EB;
  border-radius: 15px;
  padding: 32px;
}

.my-skill img {
  margin: 0px 10px;
  width: 100px;
  height: auto;
  position: relative;
}

.my-skill {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px 10px 20px 10px;
}

.application-skill {
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-skill img {
  margin: 40px 10px 0px 10px;
  width: 80px;
  height: auto;
}

.application-skill :hover img {
  transform: scale(1.15);
}

/* CONTACT */
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding-top: 120px;
  padding-bottom: 120px;
}

.container-contact {
  text-align: center;
}

.social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-separate :hover img {
  transform: scale(1.15);
}

.social-media img {
  width: 100px;
  height: auto;
  margin: 20px;
}

.social-gmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.contact h2 {
  background: linear-gradient(to right, #2563EB, #7ABCF9 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
  padding: 0px 0px 20px 0px;
}

.other-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-social-media a {
  margin: 20px;
}