@media (max-width: 2559px) and (min-width: 1440px) {
    /* HOME */
    .home h1{
        font-size: 52px;
    }

    .title-home{
        flex-basis: 60%;
    }

    /* SKILLS */
    .application-skill img {
        margin: 40px 10px 0px 10px;
        width: 70px;
        height: auto;
    }
}

@media (max-width: 1439px) and (min-width: 1024px) {
    /* TOP NAVIGATION */
    .topnav-outside{
        padding: 20px 80px 0px 80px;
    }
    
    .topnav{
        width: auto;
        height: auto;
    }
    
    .topnav a{
        padding: 20px 25px 20px 25px;
        font-size: 20px;
    }
    
    label.checkbox-label{
        margin-right: 20px;
        margin-left: 20px;
    }
    
    /* HOME */
    .home{
        padding: 200px 80px 200px 80px;
    }
    
    .title-home{
        flex-basis: 85%;
    }

    .home h2{
        font-size: 20px;
    }
    
    .home h1{
        font-size: 36px;
    }
    
    .home p{
        font-size: 20px;
    }
    
    .profile img{
        width: 450px;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    /* EXPERIENCES */
    .title {
        font-size: 20px;
    }
      
    .subtitle {
        font-size: 18px;
    }
    
    .date {
        font-size: 18px;
    }
    
    .accordion-page {
        padding-top: 120px;
        padding-bottom: 80px;
    }
    
    .accordion-page h2{
        padding: 0px 0px 20px 80px;
        font-size: 32px;
    }
    
    /* ACCORDION */
    .accordion{
        padding: 8px;
    }
    
    .accordion img {
        margin: 8px;
        width: 80px;  
        height: auto;
    }
    
    .accordion span {
        margin-left: 10px;
    }
    
    .item {
        margin-bottom: 16px;
    }
    
    .panel {
        margin: 8px 8px 0px 8px;
        padding: 20px;
        font-size: 18px;
    }
    
    .panel p {
        font-size: 18px;
    }
    
    .panel li {
        font-size: 18px;
    }
    
    .button {
        margin: 8px;
        padding: 10px 20px;
    }
    
    .button-group {
        gap: 10px;
    }
    
    /* GALLERY */
    .gallery {
        padding-top: 120px;
        padding-bottom: 80px;
    }
    
    .gallery img{
        max-width: 100%;
        height: auto;
    }
    
    .container-gallery {
        padding: 0 80px;
    }
    
    .gallery h2{
        padding: 0px 0px 20px 0px;
        font-size: 32px;
    }
    
    .gallery p{
        padding: 0px 0px 20px 0px;
        font-size: 20px;
    }
    
    /* SKILLS */
    .skills {
        height: auto;
        padding-top: 180px;
        padding-bottom: 180px;
    }
    
    .container-skills {
        padding: 0 80px;
    }
    
    .skills h2 {
        font-size: 32px;
        padding: 0px 0px 20px 0px;
    }

    .three-container {
        flex-direction: column;
    }
    
    .item-container {
        margin: 10px;
        padding: 20px;
    }
    
    .my-skill img {
        margin: 0px 10px;
        width: 80px;
        height: auto;
    }
    
    .my-skill {
        padding: 10px 10px 20px 10px;
    }
    
    .application-skill img {
        margin: 40px 10px 0px 10px;
        width: 70px;
        height: auto;
    }
    
    /* CONTACT */
    .contact {
        width: auto;
        height: auto;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .social-media img {
        width: 80px;
        height: auto;
        margin: 20px;
    }
    
    .social-gmail {
        margin-bottom: 10px;
    }
    
    .contact h2 {
        font-size: 32px;
        padding: 0px 0px 20px 0px;
    }
    
    .other-social-media a {
        margin: 20px;
    }
}  