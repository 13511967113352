@tailwind base;
@tailwind components;
@tailwind utilities;

/* DARK MODE */
body.dark {
    background-color: #171717;
}

/* TOP NAVIGATION */
body.dark .topnav {
    background-color: #000000;
    border-color: #6A748A;
}

body.dark .topnav a {
    color: #6A748A;
}

body.dark .topnav a:hover {
    color: #B15FE8;
}

body.dark label.checkbox-label{
    background-color: #B15FE8;
}

/* TOP NAVIGATION */
body.dark .home {
    background-color: #171717;
}

body.dark .home h2{
    background: linear-gradient(to right, #7A73F0, #F05E6F 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
body.dark .home h1{
    background: linear-gradient(to right, #7A73F0, #F05E6F 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.dark .home p{
    color: #FFFFFF;
}

/* EXPERIENCES */
body.dark .accordion-page {
    background-color: #121212;
}

body.dark .accordion-page h2{
    background: linear-gradient(to right, #7A73F0, #F05E6F 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* ACCORDION */
body.dark .accordion {
    background-color: #23262D;
    border: 1px solid #A5C1FF;
}

body.dark .active, body.dark .accordion:hover {
    background-color: #757E95;
  }

body.dark .accordion span {
    color: #FFFFFF;
}

body.dark .panel {
    background-color: #353A45;
    border: 1px solid #A5C1FF;
    color: #FFFFFF;
}

body.dark .button {
    background-color: #FFFFFF;
    color: #000000;
}

body.dark .button-group {
    color: #FFFFFF;
}

/* GALLERY */
body.dark .gallery{
    background-color: #171717;
}

body.dark .gallery p{
    color: #FFFFFF;
}

body.dark .gallery h2{
    background: linear-gradient(to right, #7A73F0, #F05E6F 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
/* TOOGLE BUTTON */
.checkbox {
    opacity: 0;
    position: absolute;
}

.checkbox-label {
    background-color: #111;
    width: 60px;
    height: 30px;
    border-radius: 100px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fa-moon, .fa-sun {
    color: #FFFFFF;
}

body.dark .fa-moon, .fa-sun {
    color: #000000;
}

.checkbox-label .ball {
    background-color: #FFFFFF;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
    top: 15%;
}

body.dark .checkbox-label .ball {
    background-color: #000000;
}

.checkbox:checked + .checkbox-label .ball {
    transform: translateX(36px);
}

/* SKILLS */
body.dark .skills {
    background-color: #121212;
}

body.dark .skills h2 {
    background: linear-gradient(to right, #7A73F0, #F05E6F 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.dark .item-container {
    background-color: #23262D;
    border: 1px solid #A5C1FF;
    color: #FFFFFF;
}

/* CONTACT */  
body.dark .title-contact h2 {
    background: linear-gradient(to right, #7A73F0, #F05E6F 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.dark .social-gmail .title {
    color: #FFFFFF;
}